@import "../utilities/colors";

@media (min-width: 576px) {
    .banner {
        &__header {
            font-size: 2.6rem;
        }

        &__subheader {
            font-size: 2rem;
        }

        &__logo {
            margin: 2em auto;
            width: 320px;
            height: 290px;
        }

        &__address {
            align-self: center;
            text-align: center;
            font-size: 1.8rem;
            line-height: 2.4rem;
            letter-spacing: 1px;
        }

        &__buttons {
            align-self: center;
            text-align: center;
            column-gap: normal;
        }
    }

    .footer {
        &__box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 120px 200px 50px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 1200px;
        }

        &__logo {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        &__links {
            grid-row: 1/2;
            grid-column: 2/3;
            display: flex;
            justify-content: center;
        }

        &__shortcuts {
            grid-row: 2/3;
            grid-column: 1/2;
        }

        &__courses {
            grid-row: 2/3;
            grid-column: 2/3;
        }

        &__ownership {
            grid-row: 3/4;
            grid-column: 1/-1;
        }

        &__contact {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    .news-lc {
        &__box {
            flex-direction: row;
            justify-content: stretch;
        }

        &__img {
            margin-top: 1em;
            margin-bottom: 1em;
            margin-right: 2em;
            width: 40%;
            height: 80%;
            max-height: 220px;
            max-width: 280px;
            align-self: center;
        }

        &__box-conent {
            width: 100%;
        }
    }

    .post {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        &__box {
            flex-direction: row;
        }

        &__img {
            margin-right: 1.5em;
            height: 200px;
        }
    }

    .schedule {
        &__header-row {
            th {
                font-size: 1.4rem;
            }
        }

        &__item-row {
            th,
            td {
                font-size: 1.2rem;
            }
        }
    }

    .courses-details {
        &__single-image {
            margin: 0 5px;
            // height: 200px;
        }
    }
}
