*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	font-family: sans-serif;
	scroll-behavior: smooth;
}

.wrapper {
	margin: 0 auto;
	max-width: 1200px;
}
