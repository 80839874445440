body {
    background-color: $background;
    color: $primaryText;
}

.section {
    margin: 3em auto;
    padding: 0px 20px;

    &__header {
        margin-bottom: 1em;
        font-size: 2.2rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 3px;

        @media (min-width: 768px) {
            font-size: 2.6rem;
        }

        @media (min-width: 992px) {
            font-size: 2.8rem;
        }

        @media (min-width: 1200px) {
            font-size: 3rem;
        }

        @media (min-width: 1400px) {
            font-size: 3.4rem;
        }
    }
}