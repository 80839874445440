@import "../utilities/colors";

@media (min-width: 1400px) {
    .banner {
        &__header {
            font-size: 4.8rem;
            letter-spacing: 4px;
        }

        &__subheader {
            margin: 0.5em auto;
            font-size: 2.8rem;
            letter-spacing: 7px;
        }
    }

    .schedule {    
        max-width: 1400px;

        &__header-row {
            th {
                font-size: 1.8rem;
                letter-spacing: 3px;
            }
        }
    
        &__item-row {
            th, td {
                padding: 1.2em 0.5em;
                font-size: 1.6rem;
                letter-spacing: 2px;
            }
        }
    }

    .courses {    
        max-width: 1400px;

        &-page__category {
            font-size: 2.1rem;
            letter-spacing: 3px;
        }

        &__header-row {
            th {
                font-size: 1.8rem;
                letter-spacing: 3px;
            }
        }
    
        &__item-row {
            th, td {
                padding: 1.2em 0.5em;
                font-size: 1.6rem;
                letter-spacing: 2px;
            }
        }
    }

    .courses-details {
        max-width: 1400px;

        &__vidos-box {
            flex-direction: row;
            gap: 10px;
        }

        &__header {
            font-size: 2.2rem;
        }
    }
}

@media (min-width: 1480px) {
    .nav {
        max-width: 1400px;
    }

    .banner {
        max-width: 1400px;
    }

    .offer {
        max-width: 1400px;
    }

    .about-us-sec {
        &__text {
            max-width: 1400px;
        }

        &__btn {
            max-width: 1400px;
        }
    }

    .news-sc {
        max-width: 1400px;
    }

    .smart-access {
        &__content {
            max-width: 1400px;
        }
    }

    .graphics-section {
        max-width: 1400px;
    }

    .contact-form {
        max-width: 1400px;
    }

    .footer {
        &__box {
            max-width: 1400px;
        }
    }

    .news-lc {
        max-width: 1400px;
    }

    .post {
        max-width: 1400px;
    }

    .page {
        max-width: 1400px;
    }
}
