@import "../utilities/colors";

.header {
    height: 80px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: sticky;
    padding: 5px;
    border-bottom: 1px solid #808080;
    background-color: $background;
    z-index: 250;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__logo {
        height: 65px;
        width: 140px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $background;
        z-index: 1000;

        &--invisible {
            display: none;
        }
    }

    &__menu-button {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        z-index: 1001;

        &--invisible {
            display: none;
        }
    }

    &__links {
        text-transform: uppercase;
        font-size: 1.8rem;
        letter-spacing: 2px;
        text-decoration: none;
        color: $primaryText;

        &:visited {
            text-decoration: none;
        }
    }
}

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    &__header {
        font-size: 2.2rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    &__welcome {
        display: none;
    }

    &__subheader {
        margin: 1em auto;
        font-size: 1.6rem;
        letter-spacing: 2px;
        text-align: center;
    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__logo {
        margin: 2em auto;
        width: 320px;
        height: 290px;
    }

    &__address {
        align-self: flex-start;
        text-align: left;
        font-size: 1.8rem;
        line-height: 2.4rem;
        letter-spacing: 1px;
    }

    &__tel,
    &__mail {
        display: flex;
        align-items: center;
        margin-top: 1em;

        img {
            width: 48px;
            height: 48px;
        }
    }

    &__tel-link,
    &__mail-link {
        margin-left: 0.5em;
        font-size: 2.4rem;
        text-decoration: none;
        color: $primaryText;
        letter-spacing: 1px;
        cursor: pointer;

        &:visited {
            color: $primaryText;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        column-gap: 4px;
        margin-top: 2.5em;
        width: 100%;
    }
}

.offer {
    &__cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1em;
    }

    &__card {
        padding: 2em 1em;
        border-radius: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:nth-child(odd) {
            background-color: $divider;
        }

        &:nth-child(even) {
            background-color: $lightPrimary;
        }
    }

    &__icon {
        width: 150px;
        height: 150px;
        margin-bottom: 1em;
    }

    &__card-header {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        text-align: center;
    }

    &__card-content {
        margin: 1em auto;
        text-align: center;
        font-size: 1.4rem;
        letter-spacing: 2px;
        line-height: 2rem;
    }
}

.course-sc {
    margin: 3em 2em auto 2em;
    &__box {
        justify-content: space-between;
        z-index: 10;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto 5px;
        padding: 1em 2em;
        border-radius: 1em;
        border: 1px solid $primary;
        background-color: $background;
        text-align: center;
        color: $primaryText;
        text-decoration: none;

        &:visited {
            color: $primaryText;
        }
    }

    &__img {
        width: 150px;
        height: 150px;
        margin-top: 0.6em;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__title {
        display: flex;
        align-items: center;
        min-height: 3em;
        font-size: 1.8rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        margin: 0.5em auto;
    }

    &__footer {
        position: relative;
        top: -7em;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1240px;
        min-height: 8em;
        background-color: $lightPrimary;
        border-radius: 1em;
        z-index: 5;
    }
}

.slick-next,
.slick-prev {
    &:before {
        color: $primary;
    }
}

.slick-slide {
    width: 100%;
}

.courses-list {
    margin: 3em 2em auto 2em;
    &__box {
        justify-content: space-between;
        align-items: stretch;
        z-index: 10;
    }

    &__item {
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        margin: auto 5px;
        padding: 1em 2em;
        height: 100%;
        border-radius: 1em;
        border: 1px solid $primary;
        background-color: $background;
        text-align: center;

        &-left,
        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        &__item-left {
            align-self: stretch;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__img {
        width: 75px;
        height: 75px;
    }

    &__title {
        display: flex;
        align-items: center;
        height: 3em;
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        align-self: center;
    }

    &__desctiption {
        margin: 0.5em;
        height: 7em;
        font-size: 1.2rem;
        letter-spacing: 1px;
        text-align: center;
    }

    &__price {
        align-self: center;
        justify-self: flex-end;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.2rem;
        margin-top: 4em;
    }

    &__link {
        align-self: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2rem;
    }

    &__footer {
        position: relative;
        top: -7em;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1240px;
        min-height: 8em;
        background-color: $lightPrimary;
        border-radius: 1em;
        z-index: 5;
    }
}

.about-us-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: $primary;

    &__header {
        margin-top: 1em;
        // color: $background;
    }

    &__text {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: 2px;
        // color: $background;
        text-align: justify;
    }

    &__btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        margin-bottom: 2em;
        width: 100%;
        max-width: 1200px;
    }

    &__goto {
        align-self: flex-end;
        justify-self: flex-end;
    }
}

.news-sc {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2em;
    }

    &__img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

    &__box-conent {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__title {
        margin: 1em auto;
        font-size: 1.8rem;
        letter-spacing: 2px;
    }

    &__content {
        text-align: justify;
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    &__link {
        margin-top: 1em;
        align-self: flex-end;
    }

    &__empty {
        text-align: center;
        font-size: 1.8rem;
        letter-spacing: 2px;
    }

    &__link-other {
        margin-top: 2em;
        align-self: flex-end;
    }
}

.smart-access {
    display: flex;
    flex-direction: column;
    background-color: $primary;

    &__title {
        margin-top: 1em;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
    }

    &__item {
        margin-bottom: 20px;
    }

    &__image {
        width: 200px;
        height: 200px;
        margin-right: auto;
        margin-left: auto;
    }

    &__text {
        margin-top: 2em;
        margin-bottom: 1em;
        text-align: justify;
        font-size: 1.8rem;
        letter-spacing: 2px;
        line-height: 2.2rem;
    }

    &__link {
        margin-left: auto;
        margin-right: 0;
    }
}

.graphics-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-bottom: 20px;
    }

    &__image {
        width: 200px;
        height: 200px;
        margin-right: auto;
        margin-left: auto;
    }

    &__text {
        margin-top: 2em;
        margin-bottom: 1em;
        text-align: justify;
        font-size: 1.8rem;
        letter-spacing: 2px;
        line-height: 2.2rem;
    }

    &__link {
        margin-left: auto;
        margin-right: 0;
    }
}

.student-works {
    margin: 3em 2em auto 2em;
    &__box {
        justify-content: space-between;
        z-index: 10;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto 5px;
        // padding: 1em 2em;
        border-radius: 1em;
        border: 1px solid $primary;
        background-color: $background;
        text-align: center;
        overflow: hidden;
    }

    &__img {
        width: 100%;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-content: center;
        min-height: 3em;
        font-size: 1.8rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;

        span {
            align-self: center;
        }
    }

    &__desctiption {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em 0.4em;
        min-height: 5.5em;
        font-size: 1.6rem;
        letter-spacing: 1px;
        text-align: center;
    }

    &__footer {
        position: relative;
        top: -7em;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1240px;
        min-height: 8em;
        background-color: $lightPrimary;
        border-radius: 1em;
        z-index: 5;
    }
}

.contact-form {
    max-width: 1000px;
    padding: 20px;

    &__title {
        margin-bottom: 20px;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__field {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__label {
        margin-bottom: 8px;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 1px;
    }

    &__input,
    &__select,
    &__textarea {
        padding: 10px;
        border-radius: 1em;
        border: 1px solid #ccc;
        font-size: 16px;

        &:focus,
        &:active {
            border: 1px solid $primary;
            outline: 1px solid $primary;
        }
    }

    &__textarea {
        resize: vertical;
    }

    &__select {
        width: 100%;
    }

    &__textarea {
        height: 120px;
    }

    &__button {
        align-self: flex-end;
        justify-self: flex-end;
        margin-left: auto;
        margin-right: 0;
        letter-spacing: 2px;
    }

    &__year {
        &-label,
        &-input {
            color: transparent;
            background-color: $background;
            border: 1px solid $background;
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: $divider;

    &__box {
        padding: 1em 2em;
        min-height: 30vh;
    }

    &__logo {
        width: 220px;
        height: 110px;
    }

    &__shortcuts,
    &__courses {
        display: flex;
        flex-direction: column;

        &-header {
            margin-top: 1em;
            font-size: 1.8rem;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    &__list {
        margin-top: 0.5em;
        font-size: 1.6rem;
        list-style-type: none;
    }

    &__item {
        line-height: 2.2rem;

        &-link {
            text-decoration: none;
            color: $primaryText;
            transition: color 1s;

            &:hover {
                color: $primary;
            }

            &:valid {
                color: $primaryText;
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2em;
        min-height: 5em;
    }

    &__link {
        font-size: 1.6rem;
        color: #404040;
        letter-spacing: 2px;
        text-decoration: none;
        transition: color 1s;

        &:visited {
            color: #404040;
        }

        &:hover {
            color: $primary;
        }
    }

    &__ownership {
        margin: 1em;
        font-size: 1.6rem;
        color: #404040;
    }

    &__author-box {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1em 2em;
        background-color: $secondaryText;
    }

    &__author-info {
        font-size: 1.4rem;
        letter-spacing: 2px;
        color: $background;
    }

    &__author-bold {
        font-weight: bold;
    }

    &__author-link {
        text-decoration: none;
        cursor: pointer;
        transition: color 1s;

        &:visited {
            color: $background;
        }
        &:hover {
            color: $primary;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 2em auto;
        padding: 0.5em;
        width: 100%;

        &-tel,
        &-mail,
        &-address {
            display: flex;
            align-items: center;
            margin-top: 1em;

            img {
                width: 24px;
                height: 24px;
            }

            &-link {
                align-self: center;
                margin-left: 0.5em;
                font-size: 1.8rem;
                text-decoration: none;
                color: $primaryText;
                letter-spacing: 1px;
                cursor: pointer;

                &:visited {
                    color: $primaryText;
                }
            }

            &-data {
                margin-left: 0.5em;
                font-size: 1.8rem;
                text-decoration: none;
                color: $primaryText;
                letter-spacing: 1px;
            }
        }
    }
}

// News List Page

.news-lc {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;

    &__box {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
    }

    &__box-conent {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 2rem;
        margin: 0.8em auto;
    }

    &__content {
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-align: justify;
    }

    &__link {
        margin-top: 0.8em;
        align-self: flex-end;
    }

    &__empty {
        font-size: 1.8rem;
        text-align: center;
    }
}

// POST PAGE

.post {
    display: flex;
    flex-direction: column;
    min-height: 80vh;

    &__box {
        display: flex;
        flex-direction: column;
    }

    &__content {
        margin-top: 2em;
        text-align: justify;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: 1px;
    }

    &__empty {
        text-align: center;
        font-size: 2.4rem;
    }
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &__content {
        text-align: justify;
        font-size: 1.8rem;
        letter-spacing: 2px;
        line-height: 2.6rem;
        // word-break: break-all;
        hyphens: auto; /* Domyślnie używany myślnik */
    }
}

.single-text-page {
    min-height: 80vh;
}

.errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    height: 70vh;

    &__code {
        font-size: 9.2rem;
        text-align: center;
    }

    &__message {
        margin-top: 2em;
        font-size: 3.8rem;
        text-align: center;
        text-transform: uppercase;
    }
}

.schedule {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 1200px;
    min-height: 50vh;

    &__table {
        width: 100%;
    }

    &__header-row {
        background-color: $primary;

        th {
            padding: 0.8em;
            font-size: 1.2rem;
            color: $background;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        th:first-child {
            border-top-left-radius: 1em;
        }

        th:last-child {
            border-top-right-radius: 1em;
        }
    }

    &__item-row {
        th,
        td {
            padding: 0.5em 0.2em;
        }

        &:nth-child(odd) {
            background-color: $lightPrimary;
        }

        &:nth-child(even) {
            background-color: $divider;
        }
    }

    &__info {
        font-size: 1.6rem;
    }
}

.courses {
    &-page__category {
        margin: 2em auto 1em auto;
        font-size: 1.7rem;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &__table {
        width: 100%;
    }

    &__header-row {
        background-color: $primary;

        th {
            padding: 0.8em;
            font-size: 1.2rem;
            color: $background;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        th:first-child {
            border-top-left-radius: 1em;
        }

        th:last-child {
            border-top-right-radius: 1em;
        }
    }

    &__item-row {
        th,
        td {
            padding: 0.5em 0.2em;
        }

        &:nth-child(odd) {
            background-color: $lightPrimary;
        }

        &:nth-child(even) {
            background-color: $divider;
        }
    }

    &__price {
        text-align: right;
    }
}

.app {
    &__content {
        margin: 2em auto;
    }
}

.courses-page {
    min-height: 75vh;
}

.course {
    &__btn {
        display: flex;
        flex-direction: column;
    }
    &__link-go {
        width: 30px;
        height: 20px;
        align-self: center;
        justify-self: center;
    }
}

.courses-details {
    display: flex;
    flex-direction: column;
    max-width: 1200px;

    &__box {
        display: flex;
        flex-direction: column;
    }

    &__item-img-box {
        display: flex;
        flex-direction: column;
    }

    &__image {
        width: 50%;
        align-self: center;
    }

    &__item-price {
        margin: 1em auto;
        padding: 0.5em;
        width: 100%;
        align-self: center;
        text-align: center;
        border-radius: 1em;
        background-color: $divider;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 2px;
    }

    &__details {
        display: flex;
        flex-direction: column;
        margin-bottom: 3em;
        padding: 0.5em;
        border-radius: 1em;
        background-color: $divider;
    }

    // p {
    //     font-size: 1.8rem;
    // }

    &__item {
        display: flex;
        margin: 0.5em;
        align-items: center;
    }

    &__item-img {
        width: 64px;
        height: 64px;
    }

    &__item-info {
        display: flex;
        flex-direction: column;
        margin-left: 1em;
    }

    &__item-header {
        margin-bottom: 0.5em;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &__item-value {
        font-size: 1.4rem;
    }

    &__header {
        margin: 1em auto;
        font-size: 1.8rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
    }

    &__description {
        font-size: 1.5rem;
        line-height: 2.2rem;
        text-align: justify;
        letter-spacing: 1px;
    }

    &__vidos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__vidos-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__vidos-player {
        margin: 1em auto;
    }

    &__images-box {
        margin-left: 2em;
        margin-right: 2em;
    }

    &__single-image {
        height: 200px;
    }
}

.active-form {
    display: none;
    position: fixed;
    bottom: 2em;
    right: 2em;
    z-index: 1000;

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 128px;
        padding: 8px;
        background-color: rgba($primary, 0.7);
        border-radius: 1em;
        cursor: pointer;
    }

    &__icon {
        width: 92px;
        height: 92px;
    }

    &__text {
        text-align: center;
        font-size: 1.6rem;
        letter-spacing: 1px;
    }
}

.active-form-window {
    display: none;
    position: fixed;
    top: 100px;
    left: 2em;
    right: 2em;
    bottom: 2em;
    background-color: $background;
    border-radius: 2em;
    border: 3px solid $primary;
    z-index: 1000;

    &__close {
        width: 48px;
        height: 48px;
        position: absolute;
        right: 1em;
        top: 1em;
        cursor: pointer;
    }
}

.captcha {
    display: flex;
    flex-direction: column;

    .btn {
        margin-top: 0.3em;
    }

    &__code {
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        margin-left: 1em;
    }
}

.auth-info {
    margin-top: 1.5em;
    font-size: 2.2em;
    text-align: center;
    text-transform: uppercase;
}

.alert-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 350px;
    min-height: 200px;
    padding: 10px;
    top: 50%;
    left: 50%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -150px; /* Negative half of width. */
    border-radius: 2em;
    font-size: 1.8rem;
    letter-spacing: 2px;
    color: white;
    z-index: 1005;

    &__success {
        border: 3px solid #147d43;
        background-color: #356e55;
    }

    &__error {
        border: 3px solid #bf0013;
        background-color: #8a000e;
    }

    &__close {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        width: 2em;
        height: 2em;
        z-index: 1006;
        cursor: pointer;
    }
}

.minimal-section-page-size {
    min-height: 70vh;
}

.minimal-margin-top-section {
    margin-top: 8em;
}

.cookie-consent {
    position: fixed;
    top: 100px;
    right: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    // display: none;
    max-width: 300px;
    z-index: 1010;

    &__header {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        letter-spacing: 1px;
    }
    
    &__text {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 1.5rem;
        letter-spacing: 1px;
        line-height: 1.6em;
    }
    
    &__button {
        display: block;
        margin-left: auto;
    }
}
