@import "../utilities/colors";

@media (min-width: 768px) {
    .header {
        display: flex;
        align-items: center;
    }

    .nav {
        margin-left: auto;
        margin-right: auto;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;

        &__logo {
            height: 65px;
            width: 140px;
            justify-self: flex-start;
            margin-left: 0;
            margin-left: auto;
        }

        &__items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            // gap: 5px;
            margin-left: 20px;
            margin-right: 15px;
            background-color: transparent;
            width: 100%;
            // height: 100%;
            min-height: 70px;

            &--invisible {
                display: flex;
            }
        }

        &__menu-button {
            display: none;
        }

        &__links {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            text-align: center;
            font-size: 1.6rem;
            min-height: 70px;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 1em;
            transition: background-color 1s;
            cursor: pointer;

            &:hover {
                background-color: $divider;
            }
        }

        &__smart-access {
            justify-self: flex-end;
        }
    }

    .banner {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 3em;
        padding-right: 3em;

        &__box {
            display: grid;
            grid-template-columns: 1fr 1.5fr;
            grid-template-rows: 24em 7em 4em;
            column-gap: 1em;
        }

        &__header {
            font-size: 3.4rem;
        }

        &__welcome {
            display: unset;
        }

        &__logo {
            grid-column: 2/3;
            grid-row: 1/-1;
            width: 420px;
            height: 350px;
        }

        &__address {
            grid-column: 1/2;
            grid-row: 1/2;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: left;
            bottom: 0px;
        }

        &__buttons {
            grid-column: 1/2;
            grid-row: 2/3;
            column-gap: 10px;
            height: 100%;
            align-items: center;
        }
    }

    .news-sc {
        &__box {
            flex-direction: row;
        }

        &__box-conent {
            margin-left: 2em;
        }
    }

    .offer {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        &__cards {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            column-gap: 20px;
        }

        &__card-header {
            // position: relative;
            font-size: 1.8rem;
        }

        &__card-content {
            height: 100%;
        }
    }

    .smart-access {
        &__content {
            flex-direction: row;
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__text-container {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        &__link {
            align-self: flex-end;
        }
    }

    .graphics-section {
        &__content {
            flex-direction: row;
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__text-container {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        &__link {
            align-self: flex-end;
        }
    }

    .contact-form {
        &__form {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__first-row {
            width: 50%;
        }
    }

    .footer {
        &__box {
            grid-template-columns: 290px repeat(2, 1fr);
            grid-template-rows: 120px 100px 50px;
        }

        &__shortcuts {
            grid-row: 1/-1;
            grid-column: 2/3;
        }

        &__courses {
            grid-row: 1/-1;
            grid-column: 3/4;
        }

        &__links {
            grid-row: 2/3;
            grid-column: 1/2;
        }

        &__ownership {
            grid-row: 3/4;
            grid-column: 1/2;
            margin-left: 0;
        }

        &__author-info {
            font-size: 1.6rem;
        }
    }

    .news-lc {
        &__title {
            letter-spacing: 2px;
            font-size: 2.2rem;
        }
    }

    .schedule {     
        &__header-row {
            th {
                font-size: 1.4rem;
                letter-spacing: 2px;
            }
        }
    
        &__item-row {
            th, td {
                font-size: 1.2rem;
                letter-spacing: 1px;
            }
        }
    }

    .courses {

        &-page__category {
            font-size: 2rem;
        }

        &__header-row {
            th {
                font-size: 1.4rem;
                letter-spacing: 2px;
            }
        }
    
        &__item-row {
            th, td {
                font-size: 1.2rem;
                letter-spacing: 1px;
            }
        }
    }


    .courses-details {
        
        &__box {
            flex-direction: row;
            gap: 10px;
        }

        &__item-img-box {
            width: 30%;
        }

        &__details {
            width: 70%;
        }

        &__vidos-player {
            width: 640px;
            height: 360px;
        }
        
    }
}

@media (max-width: 848px) {
    .nav {
        &__items {
            gap: 7px;
        }
    }

    .banner {
        padding-left: 5em;
        padding-right: 5em;
    }
}
