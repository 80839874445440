@import "../utilities/colors";

@media (min-width: 992px) {
    .nav {
        &__items {
            justify-content: center;
            gap: 15px;
        }
    }

    .banner {
        &__box {
            grid-template-columns: 1fr 1.8fr;
            grid-template-rows: 28em 7em 5em;
            column-gap: 1em;
        }

        &__header {
            font-size: 3.6rem;
        }

        &__subheader {
            font-size: 2.4rem;
        }

        &__logo {
            width: 490px;
            height: 390px;
        }

        &__address {
            font-size: 2.0rem;
            line-height: 2.8rem;
        }
    }

    .news-sc {
        &__title {
            font-size: 2.4rem;
        }
    }

    .footer {
        &__shortcuts, &__courses {
            &-header {
                font-size: 2.4rem;
            }
        }
    }

    .news-lc {
        &__title {
            letter-spacing: 2px;
            font-size: 2.4rem;
        }

        &__content {
            letter-spacing: 1px;
        }
    }

    .schedule {     
        &__header-row {
            th {
                font-size: 1.6rem;
                letter-spacing: 2px;
            }
        }
    
        &__item-row {
            th, td {
                font-size: 1.4rem;
                letter-spacing: 1px;
            }
        }
    }

    .courses {
        &__header-row {
            th {
                font-size: 1.6rem;
                letter-spacing: 2px;
            }
        }
    
        &__item-row {
            th, td {
                font-size: 1.4rem;
                letter-spacing: 1px;
            }
        }
    }

    .courses-list {

        &__title {
            margin-top: 0.2em;
            font-size: 1.8rem;
        }

        &__desctiption {
            font-size: 1.4rem;
        }

        &__price {
            font-size: 1.6rem;
        }
    }

    .active-form {
        display: block;
        animation: pulse 5s linear infinite;
    }
}
