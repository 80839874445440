@import './../utilities/colors';

.btn {
    padding: 0.5em 1em;
    font-size: 1.6rem;
    letter-spacing: 1px;
    border-radius: 1em;
    text-decoration: none;
    cursor: pointer;

    &:visited {
        color: $background;
    }
}

.dark-button {
    color: $background;
    background-color: $primaryText;
    transition: background-color 1s, border 1s;
    border: 2px solid transparent;

    &:hover{
        background-color: $primary;
    }

    &--light {
        &:hover {
            border: 2px solid $primaryText;
        }
    }
}

.light-button {
    color: $background;
    background-color: $primary;
    transition: background-color 1s;

    &:hover {
        background-color: $primaryText;
    }
}