.login {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        max-width: 600px;
        width: 100%;

        .card {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 20px;

            &__header {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            &__body {
                .login__form-group {
                    margin-bottom: 15px;

                    &--row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                    }

                    &--label {
                        flex: 0 0 30%;
                        text-align: right;
                        margin-right: 20px;
                    }

                    &--control {
                        flex: 1;

                        input[type="email"],
                        input[type="password"] {
                            width: 100%;
                            padding: 8px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                        }
                    }

                    &--error {
                        color: red;
                        margin-top: 5px;
                    }
                }

                .login__form-check {
                    margin-bottom: 15px;

                    input[type="checkbox"] {
                        margin-right: 5px;
                    }
                }

                .login__btn {
                    display: inline-block;
                    padding: 10px 20px;
                    border-radius: 4px;
                    text-decoration: none;
                    color: #fff;
                    cursor: pointer;

                    &--primary {
                        background-color: #007bff;
                        border: none;

                        &:hover {
                            background-color: #0056b3;
                        }
                    }

                    &--link {
                        background-color: transparent;
                        border: none;
                        color: #007bff;
                        padding: 0;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &__form-control {
        margin: 0.5em auto;
        padding: 0.5em 1em;
        border-radius: 1em;
        font-size: 1.6rem;
    }

    &__col-form-label {
        padding: 0.5em 1em;
        font-size: 1.6rem;
        letter-spacing: 2px;
    }
}

.register {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 1.4em;
    }

    label {
        margin-top: 1.2em;
        padding: 0.5em 1em;
        font-size: 1.6rem;
        letter-spacing: 2px;
    }

    &__container {
        max-width: 600px;
        width: 100%;

        .card {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 20px;

            &__header {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            &__body {
                .register__form-group {
                    margin-bottom: 15px;

                    &--row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                    }

                    &--label {
                        flex: 0 0 30%;
                        text-align: right;
                        margin-right: 20px;
                    }

                    &--control {
                        flex: 1;

                        input[type="text"],
                        input[type="email"],
                        input[type="password"] {
                            width: 100%;
                            padding: 8px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                        }
                    }

                    &--error {
                        color: red;
                        margin-top: 5px;
                    }
                }

                .register__btn {
                    display: inline-block;
                    padding: 10px 20px;
                    border-radius: 4px;
                    text-decoration: none;
                    color: #fff;
                    cursor: pointer;

                    &--primary {
                        background-color: #007bff;
                        border: none;

                        &:hover {
                            background-color: #0056b3;
                        }
                    }
                }
            }
        }
    }
}

.verification {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        max-width: 600px;
        width: 100%;

        .card {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 20px;

            &__header {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            &__body {
                .alert {
                    margin-bottom: 20px;
                }

                .verification__form-inline {
                    display: inline-block;

                    .verification__btn {
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        color: #007bff;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


.reset {
    display: flex;
    justify-content: center;
  
    .card {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
  
      .card-header {
        font-size: 1.5rem;
        font-weight: bold;
      }
  
      .card-body {
        font-size: 1rem;
  
        form {
          margin-top: 20px;
  
          .row {
            margin-bottom: 1rem;
  
            label {
              display: block;
              font-weight: bold;
              text-align: right;
              padding-right: 1rem;
            }
  
            .col-md-6 {
              flex: 1;
  
              input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
  
                &.is-invalid {
                  border-color: #dc3545;
                }
              }
            }
  
            .invalid-feedback {
              color: #dc3545;
            }
          }
  
          .btn {
            &.btn-primary {
              background-color: #007bff;
              border-color: #007bff;
            }
          }
        }
      }
    }
  }

  .email-reset {
    display: flex;
    justify-content: center;
  
    .card {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
  
      .card-header {
        font-size: 1.5rem;
        font-weight: bold;
      }
  
      .card-body {
        font-size: 1rem;
  
        .alert.alert-success {
          background-color: #d4edda;
          color: #155724;
          border-color: #c3e6cb;
          padding: 0.75rem 1.25rem;
          margin-bottom: 1rem;
          border: 1px solid transparent;
          border-radius: 0.25rem;
        }
  
        form {
          margin-top: 20px;
  
          .row {
            margin-bottom: 1rem;
  
            label {
              display: block;
              font-weight: bold;
              text-align: right;
              padding-right: 1rem;
            }
  
            .col-md-6 {
              flex: 1;
  
              input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
  
                &.is-invalid {
                  border-color: #dc3545;
                }
              }
            }
  
            .invalid-feedback {
              color: #dc3545;
            }
          }
  
          .btn {
            &.btn-primary {
              background-color: #007bff;
              border-color: #007bff;
            }
          }
        }
      }
    }
  }
  
  
.confirm {
    display: flex;
    justify-content: center;
  
    .card {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
  
      .card-header {
        font-size: 1.5rem;
        font-weight: bold;
      }
  
      .card-body {
        font-size: 1rem;
  
        form {
          margin-top: 20px;
  
          .row {
            margin-bottom: 1rem;
  
            label {
              display: block;
              font-weight: bold;
              text-align: right;
              padding-right: 1rem;
            }
  
            .col-md-6 {
              flex: 1;
  
              input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
  
                &.is-invalid {
                  border-color: #dc3545;
                }
              }
            }
  
            .invalid-feedback {
              color: #dc3545;
            }
          }
  
          .btn {
            &.btn-primary {
              background-color: #007bff;
              border-color: #007bff;
            }
          }
        }
      }
    }
  }  

