@import "../utilities/colors";

@media (min-width: 1200px) {
    .banner {
        &__box {
            margin-top: 2em;
            grid-template-columns: 1fr 1.8fr;
            grid-template-rows: 30em 7em 7em;
        }

        &__header {
            font-size: 4.0rem;
            letter-spacing: 3px;
        }

        &__subheader {
            margin: 0.5em auto;
            font-size: 2.8rem;
            letter-spacing: 4px;
        }

        &__logo {
            width: 590px;
            height: 470px;
        }

        &__address {
            font-size: 2.2rem;
            line-height: 3.2rem;
            letter-spacing: 2px;
        }
    }

    .offer {
        &__card-header {
            font-size: 2.4rem;
        }

        &__cards {
            display: flex;
            justify-content: center;
            column-gap: 40px;
        }
    }

    .courses-details {

        &__item-img-box {
            width: 20%;
        }

        &__details {
            width: 80%;
        }

        &__details {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__item {
            width: 45%;
        }

        &__header {
            font-size: 2rem;
        }

        &__description {
            line-height: 2.4rem;
            letter-spacing: 2px;
        }
    }
}

@media (min-width: 1280px) {
    .nav {
        max-width: 1200px;
    }
}