@charset "UTF-8";
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: sans-serif;
  scroll-behavior: smooth;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
}

body {
  background-color: #e9e9e9;
  color: #333333;
}

.section {
  margin: 3em auto;
  padding: 0px 20px;
}
.section__header {
  margin-bottom: 1em;
  font-size: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
}
@media (min-width: 768px) {
  .section__header {
    font-size: 2.6rem;
  }
}
@media (min-width: 992px) {
  .section__header {
    font-size: 2.8rem;
  }
}
@media (min-width: 1200px) {
  .section__header {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .section__header {
    font-size: 3.4rem;
  }
}

.btn {
  padding: 0.5em 1em;
  font-size: 1.6rem;
  letter-spacing: 1px;
  border-radius: 1em;
  text-decoration: none;
  cursor: pointer;
}
.btn:visited {
  color: #e9e9e9;
}

.dark-button {
  color: #e9e9e9;
  background-color: #333333;
  transition: background-color 1s, border 1s;
  border: 2px solid transparent;
}
.dark-button:hover {
  background-color: #ff6600;
}
.dark-button--light:hover {
  border: 2px solid #333333;
}

.light-button {
  color: #e9e9e9;
  background-color: #ff6600;
  transition: background-color 1s;
}
.light-button:hover {
  background-color: #333333;
}

.login {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  max-width: 600px;
  width: 100%;
}
.login__container .card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
}
.login__container .card__header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.login__container .card__body .login__form-group {
  margin-bottom: 15px;
}
.login__container .card__body .login__form-group--row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.login__container .card__body .login__form-group--label {
  flex: 0 0 30%;
  text-align: right;
  margin-right: 20px;
}
.login__container .card__body .login__form-group--control {
  flex: 1;
}
.login__container .card__body .login__form-group--control input[type=email],
.login__container .card__body .login__form-group--control input[type=password] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.login__container .card__body .login__form-group--error {
  color: red;
  margin-top: 5px;
}
.login__container .card__body .login__form-check {
  margin-bottom: 15px;
}
.login__container .card__body .login__form-check input[type=checkbox] {
  margin-right: 5px;
}
.login__container .card__body .login__btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.login__container .card__body .login__btn--primary {
  background-color: #007bff;
  border: none;
}
.login__container .card__body .login__btn--primary:hover {
  background-color: #0056b3;
}
.login__container .card__body .login__btn--link {
  background-color: transparent;
  border: none;
  color: #007bff;
  padding: 0;
}
.login__container .card__body .login__btn--link:hover {
  text-decoration: underline;
}
.login__form-control {
  margin: 0.5em auto;
  padding: 0.5em 1em;
  border-radius: 1em;
  font-size: 1.6rem;
}
.login__col-form-label {
  padding: 0.5em 1em;
  font-size: 1.6rem;
  letter-spacing: 2px;
}

.register {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1.4em;
}
.register label {
  margin-top: 1.2em;
  padding: 0.5em 1em;
  font-size: 1.6rem;
  letter-spacing: 2px;
}
.register__container {
  max-width: 600px;
  width: 100%;
}
.register__container .card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
}
.register__container .card__header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.register__container .card__body .register__form-group {
  margin-bottom: 15px;
}
.register__container .card__body .register__form-group--row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.register__container .card__body .register__form-group--label {
  flex: 0 0 30%;
  text-align: right;
  margin-right: 20px;
}
.register__container .card__body .register__form-group--control {
  flex: 1;
}
.register__container .card__body .register__form-group--control input[type=text],
.register__container .card__body .register__form-group--control input[type=email],
.register__container .card__body .register__form-group--control input[type=password] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.register__container .card__body .register__form-group--error {
  color: red;
  margin-top: 5px;
}
.register__container .card__body .register__btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.register__container .card__body .register__btn--primary {
  background-color: #007bff;
  border: none;
}
.register__container .card__body .register__btn--primary:hover {
  background-color: #0056b3;
}

.verification {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verification__container {
  max-width: 600px;
  width: 100%;
}
.verification__container .card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
}
.verification__container .card__header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.verification__container .card__body .alert {
  margin-bottom: 20px;
}
.verification__container .card__body .verification__form-inline {
  display: inline-block;
}
.verification__container .card__body .verification__form-inline .verification__btn {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.reset {
  display: flex;
  justify-content: center;
}
.reset .card {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}
.reset .card .card-header {
  font-size: 1.5rem;
  font-weight: bold;
}
.reset .card .card-body {
  font-size: 1rem;
}
.reset .card .card-body form {
  margin-top: 20px;
}
.reset .card .card-body form .row {
  margin-bottom: 1rem;
}
.reset .card .card-body form .row label {
  display: block;
  font-weight: bold;
  text-align: right;
  padding-right: 1rem;
}
.reset .card .card-body form .row .col-md-6 {
  flex: 1;
}
.reset .card .card-body form .row .col-md-6 input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.reset .card .card-body form .row .col-md-6 input.is-invalid {
  border-color: #dc3545;
}
.reset .card .card-body form .row .invalid-feedback {
  color: #dc3545;
}
.reset .card .card-body form .btn.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.email-reset {
  display: flex;
  justify-content: center;
}
.email-reset .card {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}
.email-reset .card .card-header {
  font-size: 1.5rem;
  font-weight: bold;
}
.email-reset .card .card-body {
  font-size: 1rem;
}
.email-reset .card .card-body .alert.alert-success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.email-reset .card .card-body form {
  margin-top: 20px;
}
.email-reset .card .card-body form .row {
  margin-bottom: 1rem;
}
.email-reset .card .card-body form .row label {
  display: block;
  font-weight: bold;
  text-align: right;
  padding-right: 1rem;
}
.email-reset .card .card-body form .row .col-md-6 {
  flex: 1;
}
.email-reset .card .card-body form .row .col-md-6 input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.email-reset .card .card-body form .row .col-md-6 input.is-invalid {
  border-color: #dc3545;
}
.email-reset .card .card-body form .row .invalid-feedback {
  color: #dc3545;
}
.email-reset .card .card-body form .btn.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.confirm {
  display: flex;
  justify-content: center;
}
.confirm .card {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}
.confirm .card .card-header {
  font-size: 1.5rem;
  font-weight: bold;
}
.confirm .card .card-body {
  font-size: 1rem;
}
.confirm .card .card-body form {
  margin-top: 20px;
}
.confirm .card .card-body form .row {
  margin-bottom: 1rem;
}
.confirm .card .card-body form .row label {
  display: block;
  font-weight: bold;
  text-align: right;
  padding-right: 1rem;
}
.confirm .card .card-body form .row .col-md-6 {
  flex: 1;
}
.confirm .card .card-body form .row .col-md-6 input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.confirm .card .card-body form .row .col-md-6 input.is-invalid {
  border-color: #dc3545;
}
.confirm .card .card-body form .row .invalid-feedback {
  color: #dc3545;
}
.confirm .card .card-body form .btn.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.03);
  }
  75% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
}
.link-web {
  padding: 0.5em 1em;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;
}
.link-web:visited {
  color: #ff6600;
}

.link-web-primary {
  color: #ff6600;
  transition: color 1s;
}
.link-web-primary:hover {
  color: #7f3300;
}

.header {
  height: 80px;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  position: sticky;
  padding: 5px;
  border-bottom: 1px solid #808080;
  background-color: #e9e9e9;
  z-index: 250;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav__logo {
  height: 65px;
  width: 140px;
}
.nav__items {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9e9e9;
  z-index: 1000;
}
.nav__items--invisible {
  display: none;
}
.nav__menu-button {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  z-index: 1001;
}
.nav__menu-button--invisible {
  display: none;
}
.nav__links {
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: #333333;
}
.nav__links:visited {
  text-decoration: none;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.banner__header {
  font-size: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.banner__welcome {
  display: none;
}
.banner__subheader {
  margin: 1em auto;
  font-size: 1.6rem;
  letter-spacing: 2px;
  text-align: center;
}
.banner__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.banner__logo {
  margin: 2em auto;
  width: 320px;
  height: 290px;
}
.banner__address {
  align-self: flex-start;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 1px;
}
.banner__tel, .banner__mail {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.banner__tel img, .banner__mail img {
  width: 48px;
  height: 48px;
}
.banner__tel-link, .banner__mail-link {
  margin-left: 0.5em;
  font-size: 2.4rem;
  text-decoration: none;
  color: #333333;
  letter-spacing: 1px;
  cursor: pointer;
}
.banner__tel-link:visited, .banner__mail-link:visited {
  color: #333333;
}
.banner__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 4px;
  margin-top: 2.5em;
  width: 100%;
}

.offer__cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
}
.offer__card {
  padding: 2em 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offer__card:nth-child(odd) {
  background-color: #cccccc;
}
.offer__card:nth-child(even) {
  background-color: #ffcc99;
}
.offer__icon {
  width: 150px;
  height: 150px;
  margin-bottom: 1em;
}
.offer__card-header {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
}
.offer__card-content {
  margin: 1em auto;
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: 2px;
  line-height: 2rem;
}

.course-sc {
  margin: 3em 2em auto 2em;
}
.course-sc__box {
  justify-content: space-between;
  z-index: 10;
}
.course-sc__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 5px;
  padding: 1em 2em;
  border-radius: 1em;
  border: 1px solid #ff6600;
  background-color: #e9e9e9;
  text-align: center;
  color: #333333;
  text-decoration: none;
}
.course-sc__item:visited {
  color: #333333;
}
.course-sc__img {
  width: 150px;
  height: 150px;
  margin-top: 0.6em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.course-sc__title {
  display: flex;
  align-items: center;
  min-height: 3em;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  margin: 0.5em auto;
}
.course-sc__footer {
  position: relative;
  top: -7em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1240px;
  min-height: 8em;
  background-color: #ffcc99;
  border-radius: 1em;
  z-index: 5;
}

.slick-next:before,
.slick-prev:before {
  color: #ff6600;
}

.slick-slide {
  width: 100%;
}

.courses-list {
  margin: 3em 2em auto 2em;
}
.courses-list__box {
  justify-content: space-between;
  align-items: stretch;
  z-index: 10;
}
.courses-list__item {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: auto 5px;
  padding: 1em 2em;
  height: 100%;
  border-radius: 1em;
  border: 1px solid #ff6600;
  background-color: #e9e9e9;
  text-align: center;
}
.courses-list__item-left, .courses-list__item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.courses-list__item__item-left {
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;
}
.courses-list__img {
  width: 75px;
  height: 75px;
}
.courses-list__title {
  display: flex;
  align-items: center;
  height: 3em;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  align-self: center;
}
.courses-list__desctiption {
  margin: 0.5em;
  height: 7em;
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-align: center;
}
.courses-list__price {
  align-self: center;
  justify-self: flex-end;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-top: 4em;
}
.courses-list__link {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
}
.courses-list__footer {
  position: relative;
  top: -7em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1240px;
  min-height: 8em;
  background-color: #ffcc99;
  border-radius: 1em;
  z-index: 5;
}

.about-us-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: #ff6600;
}
.about-us-sec__header {
  margin-top: 1em;
}
.about-us-sec__text {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 2px;
  text-align: justify;
}
.about-us-sec__btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  max-width: 1200px;
}
.about-us-sec__goto {
  align-self: flex-end;
  justify-self: flex-end;
}

.news-sc {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.news-sc__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}
.news-sc__img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.news-sc__box-conent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.news-sc__title {
  margin: 1em auto;
  font-size: 1.8rem;
  letter-spacing: 2px;
}
.news-sc__content {
  text-align: justify;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.news-sc__link {
  margin-top: 1em;
  align-self: flex-end;
}
.news-sc__empty {
  text-align: center;
  font-size: 1.8rem;
  letter-spacing: 2px;
}
.news-sc__link-other {
  margin-top: 2em;
  align-self: flex-end;
}

.smart-access {
  display: flex;
  flex-direction: column;
  background-color: #ff6600;
}
.smart-access__title {
  margin-top: 1em;
}
.smart-access__content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.smart-access__item {
  margin-bottom: 20px;
}
.smart-access__image {
  width: 200px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
}
.smart-access__text {
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: justify;
  font-size: 1.8rem;
  letter-spacing: 2px;
  line-height: 2.2rem;
}
.smart-access__link {
  margin-left: auto;
  margin-right: 0;
}

.graphics-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.graphics-section__content {
  display: flex;
  flex-direction: column;
}
.graphics-section__item {
  margin-bottom: 20px;
}
.graphics-section__image {
  width: 200px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
}
.graphics-section__text {
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: justify;
  font-size: 1.8rem;
  letter-spacing: 2px;
  line-height: 2.2rem;
}
.graphics-section__link {
  margin-left: auto;
  margin-right: 0;
}

.student-works {
  margin: 3em 2em auto 2em;
}
.student-works__box {
  justify-content: space-between;
  z-index: 10;
}
.student-works__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 5px;
  border-radius: 1em;
  border: 1px solid #ff6600;
  background-color: #e9e9e9;
  text-align: center;
  overflow: hidden;
}
.student-works__img {
  width: 100%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.student-works__title {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 3em;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}
.student-works__title span {
  align-self: center;
}
.student-works__desctiption {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.4em;
  min-height: 5.5em;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-align: center;
}
.student-works__footer {
  position: relative;
  top: -7em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1240px;
  min-height: 8em;
  background-color: #ffcc99;
  border-radius: 1em;
  z-index: 5;
}

.contact-form {
  max-width: 1000px;
  padding: 20px;
}
.contact-form__title {
  margin-bottom: 20px;
}
.contact-form__form {
  display: flex;
  flex-direction: column;
}
.contact-form__field {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact-form__label {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.contact-form__input, .contact-form__select, .contact-form__textarea {
  padding: 10px;
  border-radius: 1em;
  border: 1px solid #ccc;
  font-size: 16px;
}
.contact-form__input:focus, .contact-form__input:active, .contact-form__select:focus, .contact-form__select:active, .contact-form__textarea:focus, .contact-form__textarea:active {
  border: 1px solid #ff6600;
  outline: 1px solid #ff6600;
}
.contact-form__textarea {
  resize: vertical;
}
.contact-form__select {
  width: 100%;
}
.contact-form__textarea {
  height: 120px;
}
.contact-form__button {
  align-self: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 0;
  letter-spacing: 2px;
}
.contact-form__year-label, .contact-form__year-input {
  color: transparent;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #cccccc;
}
.footer__box {
  padding: 1em 2em;
  min-height: 30vh;
}
.footer__logo {
  width: 220px;
  height: 110px;
}
.footer__shortcuts, .footer__courses {
  display: flex;
  flex-direction: column;
}
.footer__shortcuts-header, .footer__courses-header {
  margin-top: 1em;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.footer__list {
  margin-top: 0.5em;
  font-size: 1.6rem;
  list-style-type: none;
}
.footer__item {
  line-height: 2.2rem;
}
.footer__item-link {
  text-decoration: none;
  color: #333333;
  transition: color 1s;
}
.footer__item-link:hover {
  color: #ff6600;
}
.footer__item-link:valid {
  color: #333333;
}
.footer__links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2em;
  min-height: 5em;
}
.footer__link {
  font-size: 1.6rem;
  color: #404040;
  letter-spacing: 2px;
  text-decoration: none;
  transition: color 1s;
}
.footer__link:visited {
  color: #404040;
}
.footer__link:hover {
  color: #ff6600;
}
.footer__ownership {
  margin: 1em;
  font-size: 1.6rem;
  color: #404040;
}
.footer__author-box {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em 2em;
  background-color: #999999;
}
.footer__author-info {
  font-size: 1.4rem;
  letter-spacing: 2px;
  color: #e9e9e9;
}
.footer__author-bold {
  font-weight: bold;
}
.footer__author-link {
  text-decoration: none;
  cursor: pointer;
  transition: color 1s;
}
.footer__author-link:visited {
  color: #e9e9e9;
}
.footer__author-link:hover {
  color: #ff6600;
}
.footer__contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 2em auto;
  padding: 0.5em;
  width: 100%;
}
.footer__contact-tel, .footer__contact-mail, .footer__contact-address {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.footer__contact-tel img, .footer__contact-mail img, .footer__contact-address img {
  width: 24px;
  height: 24px;
}
.footer__contact-tel-link, .footer__contact-mail-link, .footer__contact-address-link {
  align-self: center;
  margin-left: 0.5em;
  font-size: 1.8rem;
  text-decoration: none;
  color: #333333;
  letter-spacing: 1px;
  cursor: pointer;
}
.footer__contact-tel-link:visited, .footer__contact-mail-link:visited, .footer__contact-address-link:visited {
  color: #333333;
}
.footer__contact-tel-data, .footer__contact-mail-data, .footer__contact-address-data {
  margin-left: 0.5em;
  font-size: 1.8rem;
  text-decoration: none;
  color: #333333;
  letter-spacing: 1px;
}

.news-lc {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
}
.news-lc__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}
.news-lc__box-conent {
  display: flex;
  flex-direction: column;
}
.news-lc__title {
  font-size: 2rem;
  margin: 0.8em auto;
}
.news-lc__content {
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: justify;
}
.news-lc__link {
  margin-top: 0.8em;
  align-self: flex-end;
}
.news-lc__empty {
  font-size: 1.8rem;
  text-align: center;
}

.post {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}
.post__box {
  display: flex;
  flex-direction: column;
}
.post__content {
  margin-top: 2em;
  text-align: justify;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 1px;
}
.post__empty {
  text-align: center;
  font-size: 2.4rem;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.page__content {
  text-align: justify;
  font-size: 1.8rem;
  letter-spacing: 2px;
  line-height: 2.6rem;
  hyphens: auto; /* Domyślnie używany myślnik */
}

.single-text-page {
  min-height: 80vh;
}

.errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  height: 70vh;
}
.errors__code {
  font-size: 9.2rem;
  text-align: center;
}
.errors__message {
  margin-top: 2em;
  font-size: 3.8rem;
  text-align: center;
  text-transform: uppercase;
}

.schedule {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  min-height: 50vh;
}
.schedule__table {
  width: 100%;
}
.schedule__header-row {
  background-color: #ff6600;
}
.schedule__header-row th {
  padding: 0.8em;
  font-size: 1.2rem;
  color: #e9e9e9;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.schedule__header-row th:first-child {
  border-top-left-radius: 1em;
}
.schedule__header-row th:last-child {
  border-top-right-radius: 1em;
}
.schedule__item-row th,
.schedule__item-row td {
  padding: 0.5em 0.2em;
}
.schedule__item-row:nth-child(odd) {
  background-color: #ffcc99;
}
.schedule__item-row:nth-child(even) {
  background-color: #cccccc;
}
.schedule__info {
  font-size: 1.6rem;
}

.courses-page__category {
  margin: 2em auto 1em auto;
  font-size: 1.7rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.courses__table {
  width: 100%;
}
.courses__header-row {
  background-color: #ff6600;
}
.courses__header-row th {
  padding: 0.8em;
  font-size: 1.2rem;
  color: #e9e9e9;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.courses__header-row th:first-child {
  border-top-left-radius: 1em;
}
.courses__header-row th:last-child {
  border-top-right-radius: 1em;
}
.courses__item-row th,
.courses__item-row td {
  padding: 0.5em 0.2em;
}
.courses__item-row:nth-child(odd) {
  background-color: #ffcc99;
}
.courses__item-row:nth-child(even) {
  background-color: #cccccc;
}
.courses__price {
  text-align: right;
}

.app__content {
  margin: 2em auto;
}

.courses-page {
  min-height: 75vh;
}

.course__btn {
  display: flex;
  flex-direction: column;
}
.course__link-go {
  width: 30px;
  height: 20px;
  align-self: center;
  justify-self: center;
}

.courses-details {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}
.courses-details__box {
  display: flex;
  flex-direction: column;
}
.courses-details__item-img-box {
  display: flex;
  flex-direction: column;
}
.courses-details__image {
  width: 50%;
  align-self: center;
}
.courses-details__item-price {
  margin: 1em auto;
  padding: 0.5em;
  width: 100%;
  align-self: center;
  text-align: center;
  border-radius: 1em;
  background-color: #cccccc;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 2px;
}
.courses-details__details {
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
  padding: 0.5em;
  border-radius: 1em;
  background-color: #cccccc;
}
.courses-details__item {
  display: flex;
  margin: 0.5em;
  align-items: center;
}
.courses-details__item-img {
  width: 64px;
  height: 64px;
}
.courses-details__item-info {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}
.courses-details__item-header {
  margin-bottom: 0.5em;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.courses-details__item-value {
  font-size: 1.4rem;
}
.courses-details__header {
  margin: 1em auto;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}
.courses-details__description {
  font-size: 1.5rem;
  line-height: 2.2rem;
  text-align: justify;
  letter-spacing: 1px;
}
.courses-details__vidos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.courses-details__vidos-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.courses-details__vidos-player {
  margin: 1em auto;
}
.courses-details__images-box {
  margin-left: 2em;
  margin-right: 2em;
}
.courses-details__single-image {
  height: 200px;
}

.active-form {
  display: none;
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 1000;
}
.active-form__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 128px;
  padding: 8px;
  background-color: rgba(255, 102, 0, 0.7);
  border-radius: 1em;
  cursor: pointer;
}
.active-form__icon {
  width: 92px;
  height: 92px;
}
.active-form__text {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.active-form-window {
  display: none;
  position: fixed;
  top: 100px;
  left: 2em;
  right: 2em;
  bottom: 2em;
  background-color: #e9e9e9;
  border-radius: 2em;
  border: 3px solid #ff6600;
  z-index: 1000;
}
.active-form-window__close {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
}

.captcha {
  display: flex;
  flex-direction: column;
}
.captcha .btn {
  margin-top: 0.3em;
}
.captcha__code {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-left: 1em;
}

.auth-info {
  margin-top: 1.5em;
  font-size: 2.2em;
  text-align: center;
  text-transform: uppercase;
}

.alert-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 350px;
  min-height: 200px;
  padding: 10px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -150px; /* Negative half of width. */
  border-radius: 2em;
  font-size: 1.8rem;
  letter-spacing: 2px;
  color: white;
  z-index: 1005;
}
.alert-main__success {
  border: 3px solid #147d43;
  background-color: #356e55;
}
.alert-main__error {
  border: 3px solid #bf0013;
  background-color: #8a000e;
}
.alert-main__close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 2em;
  height: 2em;
  z-index: 1006;
  cursor: pointer;
}

.minimal-section-page-size {
  min-height: 70vh;
}

.minimal-margin-top-section {
  margin-top: 8em;
}

.cookie-consent {
  position: fixed;
  top: 100px;
  right: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  max-width: 300px;
  z-index: 1010;
}
.cookie-consent__header {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.cookie-consent__text {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 1.6em;
}
.cookie-consent__button {
  display: block;
  margin-left: auto;
}

@media (min-width: 576px) {
  .banner__header {
    font-size: 2.6rem;
  }
  .banner__subheader {
    font-size: 2rem;
  }
  .banner__logo {
    margin: 2em auto;
    width: 320px;
    height: 290px;
  }
  .banner__address {
    align-self: center;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 1px;
  }
  .banner__buttons {
    align-self: center;
    text-align: center;
    column-gap: normal;
  }
  .footer__box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 120px 200px 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1200px;
  }
  .footer__logo {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .footer__links {
    grid-row: 1/2;
    grid-column: 2/3;
    display: flex;
    justify-content: center;
  }
  .footer__shortcuts {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .footer__courses {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .footer__ownership {
    grid-row: 3/4;
    grid-column: 1/-1;
  }
  .footer__contact {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .news-lc__box {
    flex-direction: row;
    justify-content: stretch;
  }
  .news-lc__img {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 2em;
    width: 40%;
    height: 80%;
    max-height: 220px;
    max-width: 280px;
    align-self: center;
  }
  .news-lc__box-conent {
    width: 100%;
  }
  .post {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .post__box {
    flex-direction: row;
  }
  .post__img {
    margin-right: 1.5em;
    height: 200px;
  }
  .schedule__header-row th {
    font-size: 1.4rem;
  }
  .schedule__item-row th,
  .schedule__item-row td {
    font-size: 1.2rem;
  }
  .courses-details__single-image {
    margin: 0 5px;
  }
}
@media (min-width: 768px) {
  .header {
    display: flex;
    align-items: center;
  }
  .nav {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .nav__logo {
    height: 65px;
    width: 140px;
    justify-self: flex-start;
    margin-left: 0;
    margin-left: auto;
  }
  .nav__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-left: 20px;
    margin-right: 15px;
    background-color: transparent;
    width: 100%;
    min-height: 70px;
  }
  .nav__items--invisible {
    display: flex;
  }
  .nav__menu-button {
    display: none;
  }
  .nav__links {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    text-align: center;
    font-size: 1.6rem;
    min-height: 70px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 1em;
    transition: background-color 1s;
    cursor: pointer;
  }
  .nav__links:hover {
    background-color: #cccccc;
  }
  .nav__smart-access {
    justify-self: flex-end;
  }
  .banner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3em;
    padding-right: 3em;
  }
  .banner__box {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 24em 7em 4em;
    column-gap: 1em;
  }
  .banner__header {
    font-size: 3.4rem;
  }
  .banner__welcome {
    display: unset;
  }
  .banner__logo {
    grid-column: 2/3;
    grid-row: 1/-1;
    width: 420px;
    height: 350px;
  }
  .banner__address {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    bottom: 0px;
  }
  .banner__buttons {
    grid-column: 1/2;
    grid-row: 2/3;
    column-gap: 10px;
    height: 100%;
    align-items: center;
  }
  .news-sc__box {
    flex-direction: row;
  }
  .news-sc__box-conent {
    margin-left: 2em;
  }
  .offer {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .offer__cards {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    column-gap: 20px;
  }
  .offer__card-header {
    font-size: 1.8rem;
  }
  .offer__card-content {
    height: 100%;
  }
  .smart-access__content {
    flex-direction: row;
  }
  .smart-access__item {
    display: flex;
    align-items: center;
  }
  .smart-access__text-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .smart-access__link {
    align-self: flex-end;
  }
  .graphics-section__content {
    flex-direction: row;
  }
  .graphics-section__item {
    display: flex;
    align-items: center;
  }
  .graphics-section__text-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .graphics-section__link {
    align-self: flex-end;
  }
  .contact-form__form {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .contact-form__first-row {
    width: 50%;
  }
  .footer__box {
    grid-template-columns: 290px repeat(2, 1fr);
    grid-template-rows: 120px 100px 50px;
  }
  .footer__shortcuts {
    grid-row: 1/-1;
    grid-column: 2/3;
  }
  .footer__courses {
    grid-row: 1/-1;
    grid-column: 3/4;
  }
  .footer__links {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .footer__ownership {
    grid-row: 3/4;
    grid-column: 1/2;
    margin-left: 0;
  }
  .footer__author-info {
    font-size: 1.6rem;
  }
  .news-lc__title {
    letter-spacing: 2px;
    font-size: 2.2rem;
  }
  .schedule__header-row th {
    font-size: 1.4rem;
    letter-spacing: 2px;
  }
  .schedule__item-row th, .schedule__item-row td {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .courses-page__category {
    font-size: 2rem;
  }
  .courses__header-row th {
    font-size: 1.4rem;
    letter-spacing: 2px;
  }
  .courses__item-row th, .courses__item-row td {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .courses-details__box {
    flex-direction: row;
    gap: 10px;
  }
  .courses-details__item-img-box {
    width: 30%;
  }
  .courses-details__details {
    width: 70%;
  }
  .courses-details__vidos-player {
    width: 640px;
    height: 360px;
  }
}
@media (max-width: 848px) {
  .nav__items {
    gap: 7px;
  }
  .banner {
    padding-left: 5em;
    padding-right: 5em;
  }
}
@media (min-width: 992px) {
  .nav__items {
    justify-content: center;
    gap: 15px;
  }
  .banner__box {
    grid-template-columns: 1fr 1.8fr;
    grid-template-rows: 28em 7em 5em;
    column-gap: 1em;
  }
  .banner__header {
    font-size: 3.6rem;
  }
  .banner__subheader {
    font-size: 2.4rem;
  }
  .banner__logo {
    width: 490px;
    height: 390px;
  }
  .banner__address {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .news-sc__title {
    font-size: 2.4rem;
  }
  .footer__shortcuts-header, .footer__courses-header {
    font-size: 2.4rem;
  }
  .news-lc__title {
    letter-spacing: 2px;
    font-size: 2.4rem;
  }
  .news-lc__content {
    letter-spacing: 1px;
  }
  .schedule__header-row th {
    font-size: 1.6rem;
    letter-spacing: 2px;
  }
  .schedule__item-row th, .schedule__item-row td {
    font-size: 1.4rem;
    letter-spacing: 1px;
  }
  .courses__header-row th {
    font-size: 1.6rem;
    letter-spacing: 2px;
  }
  .courses__item-row th, .courses__item-row td {
    font-size: 1.4rem;
    letter-spacing: 1px;
  }
  .courses-list__title {
    margin-top: 0.2em;
    font-size: 1.8rem;
  }
  .courses-list__desctiption {
    font-size: 1.4rem;
  }
  .courses-list__price {
    font-size: 1.6rem;
  }
  .active-form {
    display: block;
    animation: pulse 5s linear infinite;
  }
}
@media (min-width: 1200px) {
  .banner__box {
    margin-top: 2em;
    grid-template-columns: 1fr 1.8fr;
    grid-template-rows: 30em 7em 7em;
  }
  .banner__header {
    font-size: 4rem;
    letter-spacing: 3px;
  }
  .banner__subheader {
    margin: 0.5em auto;
    font-size: 2.8rem;
    letter-spacing: 4px;
  }
  .banner__logo {
    width: 590px;
    height: 470px;
  }
  .banner__address {
    font-size: 2.2rem;
    line-height: 3.2rem;
    letter-spacing: 2px;
  }
  .offer__card-header {
    font-size: 2.4rem;
  }
  .offer__cards {
    display: flex;
    justify-content: center;
    column-gap: 40px;
  }
  .courses-details__item-img-box {
    width: 20%;
  }
  .courses-details__details {
    width: 80%;
  }
  .courses-details__details {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .courses-details__item {
    width: 45%;
  }
  .courses-details__header {
    font-size: 2rem;
  }
  .courses-details__description {
    line-height: 2.4rem;
    letter-spacing: 2px;
  }
}
@media (min-width: 1280px) {
  .nav {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .banner__header {
    font-size: 4.8rem;
    letter-spacing: 4px;
  }
  .banner__subheader {
    margin: 0.5em auto;
    font-size: 2.8rem;
    letter-spacing: 7px;
  }
  .schedule {
    max-width: 1400px;
  }
  .schedule__header-row th {
    font-size: 1.8rem;
    letter-spacing: 3px;
  }
  .schedule__item-row th, .schedule__item-row td {
    padding: 1.2em 0.5em;
    font-size: 1.6rem;
    letter-spacing: 2px;
  }
  .courses {
    max-width: 1400px;
  }
  .courses-page__category {
    font-size: 2.1rem;
    letter-spacing: 3px;
  }
  .courses__header-row th {
    font-size: 1.8rem;
    letter-spacing: 3px;
  }
  .courses__item-row th, .courses__item-row td {
    padding: 1.2em 0.5em;
    font-size: 1.6rem;
    letter-spacing: 2px;
  }
  .courses-details {
    max-width: 1400px;
  }
  .courses-details__vidos-box {
    flex-direction: row;
    gap: 10px;
  }
  .courses-details__header {
    font-size: 2.2rem;
  }
}
@media (min-width: 1480px) {
  .nav {
    max-width: 1400px;
  }
  .banner {
    max-width: 1400px;
  }
  .offer {
    max-width: 1400px;
  }
  .about-us-sec__text {
    max-width: 1400px;
  }
  .about-us-sec__btn {
    max-width: 1400px;
  }
  .news-sc {
    max-width: 1400px;
  }
  .smart-access__content {
    max-width: 1400px;
  }
  .graphics-section {
    max-width: 1400px;
  }
  .contact-form {
    max-width: 1400px;
  }
  .footer__box {
    max-width: 1400px;
  }
  .news-lc {
    max-width: 1400px;
  }
  .post {
    max-width: 1400px;
  }
  .page {
    max-width: 1400px;
  }
}