@import './../utilities/colors';

.link-web {
    padding: 0.5em 1em;
    font-size: 1.6rem;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;

    &:visited {
        color: $primary;
    }
}

.link-web-primary {
    color: $primary;
    transition: color 1s;

    &:hover{
        color: $darkPrimary;
    }
}